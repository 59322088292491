<template>
  <base-timeline :items="timelineEntries" :color="color" date_field="date_cible">
    <template slot-scope="entry">
      <TaskCard :task="entry.item" @menuClick="onMenuClick" />
    </template>
  </base-timeline>
</template>

<script>
import { mapGetters } from 'vuex'
import { TaskCard } from './'

export default {
  name: 'TasksTimeline',
  props: {
    tasks: { type: Array, default: () => ([]) },
    color: { type: String }
  },
  components: { TaskCard },

  computed: {
    ...mapGetters({
      isAdmin: 'auth/isAdmin',
      favorites: 'favorites/getFavorites'
    }),

    timelineEntries () {
      return this.tasks.map(task => ({
        ...task,
        actions: [
          { label: this.$t('tasks.edit_task'), payload: { name: 'task-show', params: { id: task.id } } }
        ],
        is_favorite: !!this.favorites && !!this.favorites['task'] &&
          this.favorites['task'].some(favorite => favorite.id === task.id),
        model: { model_type: 'tache', model_id: task.id }
      }))
    }
  },

  methods: {

    onMenuClick ({ name, params }) {
      this.$router.push({ name, params })
    }
  }
}
</script>

<style lang="stylus" scoped>
</style>
