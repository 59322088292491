<template>
  <base-card v-bind="task" @menuClick="menuClick">
    <q-avatar slot='avatar' size="5rem" :text-color="color" icon="build" />

    <q-item-label caption>{{$formatDate(task.date_cible)}}</q-item-label>
    <q-item-label class="q-mb-md"><strong>{{task.label}}</strong></q-item-label>

    <div class="row justify-between items-end">
      <q-item-label class="col" caption>{{task.auteur.display_name}}</q-item-label>
      <q-item-label class="col" caption>{{task.destinataire.display_name}}</q-item-label>
    </div>

    <div slot="indicators" class="q-pa-md column justify-between">
      <q-btn size="md" color="grey-6" outline :icon="$getIcon('qualification', task.qualification)" />
    </div>
  </base-card>
</template>

<script>
export default {
  name: 'TaskCard',
  props: ['task', 'menuOptions', 'color'],

  methods: {
    menuClick ({ name, params }) {
      this.$router.push({ name, params })
    }
  }
}
</script>

<style>
</style>
