<template>
  <div class="row" v-if="entity">
    <div class="col col-md-8">
      <div class="column">
        <base-add-button :label="$t('tasks.add_task')" color="reseau"
          :to="{ name: 'task-create', params: { model_type: 'entity', model_object: this.entity } }" />

        <TasksTimeline :tasks="tasks" color="reseau" @updated="fetchTasks" />
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { TasksTimeline } from '../../components/tasks'

export default {
  name: 'EntitiesTasks',
  components: { TasksTimeline },

  preFetch ({ store, currentRoute, previousRoute, redirect, ssrContext }) {
    if (!store.getters['tasks/getFilters']) {
      store.dispatch('tasks/fetchFilters')
    }
  },

  computed: {
    ...mapGetters({
      entity: 'entities/getCurrentEntity',
      getTasksFor: 'tasks/getTasksFor',
      filters: 'tasks/getFilters'
    }),

    tasks () {
      return this.getTasksFor('entity', this.entity.id)
    }
  },

  mounted () {
    if (this.filters) {
      this.fetchTasks()
    }
  },

  watch: {
    filters: {
      handler (newVal, oldVal) {
        this.fetchTasks()
      },
      deep: true
    }
  },

  methods: {
    fetchTasks () {
      let params = {}
      this.filters.forEach(filter => { params[filter.id] = filter.value })

      return this.$store.dispatch('tasks/fetchTasks', {
        ...params,
        model_type: 'entity',
        model_id: this.$route.params.id,
        rowsPerPage: 0
      }).finally(() => { this.$q.loading.hide() })
    }
  }
}
</script>

<style>
</style>
